import React from 'react';
import '../../Policies.css';

export const PrivacyPolicy = () => {
    return (
        <div
            style={{ marginTop: '70px' }}
            className='sm:container md:container xmd:container lg:container xl:container mx-auto xl:px-20 px-4 py-8 md:py-16'
        >
            <p>
                <strong>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI</strong>
            </p>
            <p>
                Lo scopo del presente documento &egrave; di informare la persona
                fisica (di seguito <strong>&ldquo;Interessato&rdquo;</strong>)
                relativamente al trattamento dei suoi dati personali (di seguito{' '}
                <strong>&ldquo;Dati Personali&rdquo;</strong>) raccolti dal
                Titolare del trattamento: <strong>AiGot S.r.l.</strong>, con
                sede legale in Milano (MI) via Visconti di Modrone 38, cap
                20122, CF/P.IVA 11498080966, indirizzo e-mail{' '}
                <em>privacy@aigot.com</em>, indirizzo PEC <em>aigot@pec.com</em>
                , telefono 0500985159 (di seguito &ldquo;<strong>AiGot</strong>
                &rdquo; o <strong>&ldquo;Titolare&rdquo;</strong>).
            </p>

            <ol>
                <li>
                    <strong> CATEGORIE DI DATI PERSONALI TRATTATI</strong>
                </li>
            </ol>
            <p>
                l Titolare tratta le seguenti tipologie di Dati Personali
                forniti dall&rsquo;Interessato:
            </p>
            <ul>
                <li>
                    <strong>Dati di contatto</strong>: nome, cognome, indirizzo,
                    e-mail, recapito telefonico, codice fiscale, credenziali di
                    autenticazione al sito web e app.
                </li>
                <li>
                    <strong>Dati fiscali e di pagamento</strong>: partita IVA,
                    dati di acquisto, dati di pagamento
                </li>
                <li>
                    <strong>Dati tecnici</strong>: dati di navigazione,
                    indirizzo IP, indicazione tipo di browser, indicazione
                    provider internet, dati di localizzazione.
                </li>
                <li>
                    <strong>Dati particolari</strong> di cui all&rsquo;art. 9
                    GDPR, a titolo esemplificativo dati relativi alla salute
                    dell&rsquo;Interessato con particolare riferimento ad
                    allergie e intolleranze alimentari.
                </li>
            </ul>
            <p>
                L&rsquo;Interessato che&nbsp; comunichi&nbsp; al&nbsp;
                Titolare&nbsp; Dati&nbsp; Personali&nbsp; di&nbsp; terzi&nbsp;
                &egrave;&nbsp; direttamente&nbsp; ed&nbsp; esclusivamente
                responsabile della provenienza, raccolta, trattamento,
                comunicazione e diffusione di tali dati.
            </p>
            <ol start='2'>
                <li>
                    <strong> FINALIT&Agrave; DEL TRATTAMENTO </strong>
                </li>
                <li>
                    <strong>
                        Registrazione e autenticazione dell&rsquo;Interessato
                        sui siti web del Titolare ed utilizzo del sito
                    </strong>
                    :
                </li>
            </ol>
            <p>- creazione di un profilo sui siti web del Titolare</p>
            <p>- accesso al profilo personale dell'Interessato</p>
            <p>
                - identificazione dell&rsquo;Interessato, anche tramite
                piattaforme esterne
            </p>
            <p>
                - acquisto di prodotti e servizi del Titolare tramite i siti web
                di questo
            </p>
            <p>
                Per tutte le suddette finalit&agrave; il conferimento dei dati
                &egrave; necessario. Il rifiuto di fornire i dati non consente
                l'esecuzione dei servizi richiesti dall&rsquo;Interessato.
            </p>
            <ol>
                <li>
                    <strong>
                        Erogazione di servizi in favore dell&rsquo;Interessato
                        ed adempimento degli obblighi derivanti
                        dall&rsquo;eventuale rapporto precontrattuale o
                        contrattuale in essere con l&rsquo;Interessato
                    </strong>
                </li>
            </ol>
            <ul>
                <li>
                    vendita di prodotti e servizi dell&rsquo;Interessato secondo
                    quanto contrattualmente previsto;
                </li>
                <li>
                    contatti diretti con l&rsquo;Interessato per rispondere alle
                    richieste di questo nonch&eacute; al fine di fornire
                    assistenza e supporto;
                </li>
                <li>
                    adempimenti relativi ai pagamenti effettuati
                    dall&rsquo;Interessato.
                </li>
            </ul>
            <p>
                Per tutte le suddette finalit&agrave; il conferimento dei dati
                &egrave; necessario. Il rifiuto di fornire i dati non consente
                l'esecuzione dei servizi richiesti dall&rsquo;Interessato.
            </p>
            <ol>
                <li>
                    <strong>
                        Finalit&agrave; relative alla gestione, ottimizzazione,
                        manutenzione dei sistemi
                    </strong>
                </li>
            </ol>
            <ul>
                <li>
                    gestione,&nbsp; ottimizzazione&nbsp; e&nbsp;
                    monitoraggio&nbsp; dell&rsquo;infrastruttura&nbsp; tecnica
                    dei siti web del Titolare;
                </li>
                <li>
                    gestione, ottimizzazione e monitoraggio
                    dell&rsquo;infrastruttura tecnica sottesa ai prodotti ed ai
                    servizi offerti dal Titolare;
                </li>
                <li>
                    identificazione e risoluzione di&nbsp; eventuali&nbsp;
                    problemi&nbsp; tecnici dei sistemi informatici;
                </li>
                <li>
                    gestione e organizzazione delle informazioni dei sistemi
                    informatici (ad es. server, database etc.)
                </li>
                <li>
                    miglioramento delle&nbsp; performance&nbsp; dei sistemi
                    informatici, dei software, dei prodotti e dei servizi
                    offerti dal Titolare.
                </li>
            </ul>
            <p>
                Per tutte le suddette finalit&agrave; il conferimento dei dati
                &egrave; necessario. Il rifiuto di fornire i dati non consente
                l'esecuzione dei servizi richiesti dall&rsquo;Interessato.
            </p>
            <ol>
                <li>
                    <strong>
                        Finalit&agrave; di marketing previo consenso
                        dell&rsquo;Interessato:
                    </strong>
                </li>
            </ol>
            <ul>
                <li>
                    invio di comunicazioni commerciali con modalit&agrave; di
                    contatto automatizzate e non (ad es. sms, e-mail, notifiche
                    push tramite app e messaggistica istantanea), tradizionali
                    (ad es. telefonate, posta ordinaria), canali online (ad. es.
                    notifiche pop up sul sito web) o attraverso social network e
                    altre piattaforme web;
                </li>
                <li>
                    elaborazione statistica per lo sviluppo di Campagne
                    Marketing;
                </li>
                <li>
                    raccolta automatizzata delle&nbsp; informazioni&nbsp;
                    personali&nbsp; dell&rsquo;Interessato volta ad
                    analizzare,&nbsp; prevedere&nbsp; e&nbsp; valutare le&nbsp;
                    sue&nbsp; preferenze&nbsp; o comportamenti (c.d.
                    profilazione);
                </li>
                <li>
                    Ricerche di mercato con&nbsp; modalit&agrave;&nbsp;
                    automatizzate&nbsp; e tradizionali
                </li>
            </ul>
            <ol>
                <li>
                    <strong>
                        Adempimento di obblighi previsti dalle normative vigenti
                        o da un&rsquo;ordine dell&rsquo;Autorit&agrave;
                    </strong>
                </li>
                <li>
                    <strong>
                        Finalit&agrave; di prevenzione e repressione di frodi /
                        abusi / attivit&agrave; fraudolente
                    </strong>
                </li>
                <li>
                    <strong>
                        Accertamento, esercizio e difesa di diritti{' '}
                    </strong>
                    in qualunque sede, giudiziale e stragiudiziale, ed in
                    qualunque grado sino all&rsquo;esaurimento dei termini di
                    esperibilit&agrave; delle impugnazioni e delle azioni
                    previste dall'ordinamento.
                </li>
                <li>
                    <strong>
                        {' '}
                        MODALIT&Agrave; DI TRATTAMENTO DEI DATI PERSONALI E
                        SOGGETTI AUTORIZZATI
                    </strong>
                </li>
            </ol>
            <p>
                Il trattamento dei Dati Personali viene effettuato mediante
                strumenti cartacei e/o informatizzati. L&rsquo;acquisizione dei
                dati di cui sopra &egrave; strettamente correlata alle
                finalit&agrave; indicate&nbsp;dalla presente Informativa e&nbsp;
                mediante&nbsp; l'adozione&nbsp; di adeguate misure di sicurezza.
            </p>
            <p>
                Tutte le operazioni di trattamento dei dati sono attuate in modo
                da garantire l&rsquo;integrit&agrave;, la riservatezza e la
                disponibilit&agrave; dei dati personali.
            </p>
            <p>I Dati Personali sono trattati esclusivamente da:</p>
            <ul>
                <li>
                    persone&nbsp; autorizzate&nbsp; dal Titolare&nbsp; del&nbsp;
                    trattamento che&nbsp; si&nbsp; sono&nbsp; impegnate&nbsp;
                    alla riservatezza o che hanno un adeguato obbligo legale di
                    riservatezza;
                </li>
                <li>
                    soggetti che operano in autonomia come distinti titolari del
                    trattamento o da soggetti designati quali responsabili del
                    trattamento dal Titolare al fine di svolgere tutte le
                    attivit&agrave; di trattamento necessarie a perseguire le
                    finalit&agrave;&nbsp; di&nbsp; cui&nbsp; alla&nbsp; presente
                    Informativa&nbsp; (ad&nbsp; esempio&nbsp; Partner&nbsp;
                    commerciali,&nbsp; consulenti, societ&agrave; informatiche,
                    fornitori di servizi, hosting provider);
                </li>
                <li>
                    soggetti o enti a cui &egrave; necessario comunicare i Dati
                    Personali ai sensi dell&rsquo;art. 4 o per obbligo di legge
                    o per ordine dell&rsquo;Autorit&agrave;.
                </li>
            </ul>
            <p>
                I soggetti sopra elencati sono tenuti a utilizzare le dovute
                cautele previste dalla legge nonch&eacute; tutti i comportamenti
                che si rendano necessari nel caso concreto al fine di proteggere
                i Dati Personali.
            </p>
            <p>
                Tali soggetti possono accedere solo a quei dati necessari per
                eseguire i compiti a loro assegnati.
            </p>
            <p>
                I Dati Personali non verranno diffusi indiscriminatamente in
                alcun modo.
            </p>
            <ol start='4'>
                <li>
                    <strong> AMBITO DI COMUNICAZIONE</strong>
                </li>
            </ol>
            <p>
                I dati personali dell&rsquo;Interessato potranno essere
                comunicati a soggetti quali, a titolo esemplificativo,
                autorit&agrave; di vigilanza e controllo, soggetti pubblici
                legittimati, quali l&rsquo;Autorit&agrave; giudiziaria e/o di
                pubblica sicurezza, societ&agrave; del gruppo cui il Titolare
                eventualmente appartenga.&nbsp;
            </p>
            <p>
                I dati potranno inoltre essere trattati per conto del Titolare
                da soggetti designati responsabili del trattamento quali, ad
                esempio, societ&agrave; che erogano servizi informatici
                (manutenzione, hosting, mailing, invio di sms), Partner&nbsp;
                commerciali, consulenti, societ&agrave; del gruppo cui il
                Titolare eventualmente appartengano per servizi infragruppo,
                Meta e Google.
            </p>
            <ol start='5'>
                <li>
                    <strong>
                        {' '}
                        PERIODO DI CONSERVAZIONE DEI DATI PERSONALI
                    </strong>
                </li>
            </ol>
            <p>
                I&nbsp; Dati&nbsp; Personali&nbsp; saranno conservati per&nbsp;
                il periodo di&nbsp; tempo necessario per il raggiungimento delle
                finalit&agrave;&nbsp; per&nbsp; i quali sono stati raccolti, in
                particolare:
            </p>
            <ul>
                <li>
                    i Dati Personali raccolti per la registrazione e
                    autenticazione dell&rsquo;Interessato sui siti web del
                    Titolare ed utilizzo del sito ( <strong>3 let. A</strong>) e
                    per l&rsquo;erogazione di servizi in favore
                    dell&rsquo;Interessato ed adempimento degli obblighi
                    derivanti dal rapporto precontrattuale o contrattuale in
                    essere con l&rsquo;Interessato (<strong>art.</strong>{' '}
                    <strong>3 let. B</strong>) saranno conservati per tutta la
                    durata di tali rapporti e, dopo la cessazione, per il
                    periodo di prescrizione ordinario pari a 10 anni;
                </li>
                <li>
                    i Dati Personali raccolti per finalit&agrave; relative alla
                    gestione, ottimizzazione, manutenzione dei sistemi (
                    <strong> 3 let. C</strong>) saranno conservati per tutta la
                    durata del rapporto con l&rsquo;Interessato e, dopo la
                    cessazione, per il periodo di prescrizione ordinario pari a
                    10 anni;
                </li>
                <li>
                    i Dati Personali raccolti per Finalit&agrave; di marketing,
                    statistica, profilazione e ricerche di mercato (
                    <strong> 3 let. D</strong>) saranno trattati sino alla
                    revoca del consenso da parte dell&rsquo;Interessato e
                    comunque.
                </li>
                <li>
                    i Dati Personali raccolti per adempimento degli obblighi
                    previsti dalle normative vigenti (<strong> 3 let. E</strong>
                    ), quelli raccolti su ordine dell&rsquo;Autorit&agrave; (
                    <strong>art. 3 let. E</strong>) e quelli raccolti raccolti
                    per finalit&agrave; di prevenzione e repressione di frodi /
                    abusi / attivit&agrave; fraudolente (
                    <strong>art. 3 let. F</strong>) saranno conservati&nbsp; nei
                    termini previsti da tali normative e&nbsp; comunque&nbsp;
                    sino&nbsp; al termine prescrizionale previsto dalle norme
                    vigenti;
                </li>
                <li>
                    i Dati personali raccolti per accertare, esercitare
                    difendere i diritti del Titolare (<strong> 3 let. G</strong>
                    ) saranno conservati per tutta la durata del contenzioso, in
                    qualunque sede, giudiziale e stragiudiziale, ed in qualunque
                    grado sino all&rsquo;esaurimento dei termini di
                    esperibilit&agrave; delle impugnazioni e delle azioni
                    previste dall'ordinamento.
                </li>
            </ul>
            <p>
                Al&nbsp; termine&nbsp; del&nbsp; periodo&nbsp; di&nbsp;
                conservazione,&nbsp; tutti&nbsp; i&nbsp; Dati&nbsp;
                Personali&nbsp; saranno&nbsp; cancellati&nbsp; o&nbsp;
                conservati&nbsp; in&nbsp; una forma che non consenta
                l&rsquo;identificazione dell&rsquo;Interessato.
            </p>
            <ol start='6'>
                <li>
                    <strong> DIRITTI DELL&rsquo;INTERESSATO</strong>
                </li>
            </ol>
            <p>
                In relazione ai dati oggetto del trattamento di cui alla
                presente informativa, all&rsquo;Interessato &egrave;
                riconosciuto in qualsiasi momento il diritto di:
            </p>
            <ul>
                <li>
                    essere informato sul trattamento dei propri Dati Personali
                </li>
                <li>accedere ai propri Dati Personali (art 15 GDPR)</li>
                <li>
                    chiedere la rettifica dei propri Dati Personali (art. 16
                    GDPR)
                </li>
                <li>
                    ottenere la cancellazione dei propri Dati Personali
                    (&laquo;diritto all&rsquo;oblio&raquo; - art. 17 GDPR),
                    salvo che non sussista fondamento giuridico per il
                    trattamento il titolare del trattamento si astiene dal
                    trattare ulteriormente i dati personali. Il titolare si
                    riserva di dimostrare l&rsquo;esistenza di motivi legittimi
                    congenti per procedere al trattamento che prevalgono sugli
                    interessi, sui diritti, e sulle libert&agrave;
                    dell&rsquo;interessato oppure per l'accertamento,
                    l&rsquo;esercizio o la difesa di un diritto in sede
                    giudiziaria
                </li>
                <li>
                    limitare il trattamento dei propri Dati Personali (art. 18
                    GDPR)
                </li>
                <li>
                    di trasferire o chiedere al Titolare la trasmissione dei
                    propri Dati Personali ad altro titolare (art 20 GDPR)
                </li>
                <li>
                    l&rsquo;interessato ha il diritto di opporsi in qualsiasi
                    momento al trattamento dei propri Dati Personali (art. 21
                    GDPR) salvo che non sussista fondamento giuridico per il
                    trattamento
                </li>
                <li>
                    opporsi al trattamento automatizzato dei propri Dati
                    Personali (art. 22 GDPR)
                </li>
                <li>
                    revocare in qualsiasi momento il consenso prestato per
                    finalit&agrave; di marketing e profilazione
                </li>
                <li>
                    proporre reclamo all&rsquo;Autorit&agrave; Garante per la
                    Protezione dei dati personali e/o agire in sede giudiziale.
                </li>
            </ul>

            <p>
                Per esercitare i propri diritti, gli Interessati possono inviare
                una richieste al seguente indirizzo mail:
                <strong>
                    <em> privacy@aigot.com</em>
                </strong>
                <em>. </em>
            </p>
            <ol start='7'>
                <li>
                    <strong>
                        RESPONSABILE DELLA PROTEZIONE DEI DATI PERSONALI
                    </strong>
                </li>
            </ol>
            <p>
                <span>
                    Il Titolare del trattamento ha nominato Responsabile della
                    protezione dei dati (di seguito DPO) l&rsquo; Avv. Federica
                    Puglisi.&nbsp;
                </span>
            </p>
            <p>
                <span>
                    Il DPO pu&ograve; essere contattato al seguente indirizzo
                    email: privacy@aigot.com
                </span>
            </p>

            <p>
                L&rsquo;Informativa pu&ograve; subire modifiche. Si invita
                pertanto, l&rsquo;utente a controllare periodicamente la
                presente pagina.
            </p>
            <p>Ultimo aggiornamento: 9/11/2023</p>
        </div>
    );
};
