import React from 'react';
import Image2 from '../../../Assets/Image/people_section_2.png';
import Image3 from '../../../Assets/Image/people_section_3.png';
import './KeyPeople.css';
import { WeMakeItEasy } from '../WeMakeItEasy';

export const DesktopPeopleGrid = ({
    Image1,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
}) => (
    <div className='desktop-grid'>
        <img
            className='desktop-img-1 width-height-auto z-2'
            alt=''
            src={Image1}
        />
        <img
            className='desktop-img-2  '
            alt=''
            src={Image2}
        />
        <img
            className='desktop-img-3  '
            alt=''
            src={Image3}
        />
        <img
            className='desktop-img-4 width-height-auto z-2'
            alt=''
            src={Image4}
        />
        <img
            className='desktop-img-5 '
            alt=''
            src={Image5}
        />
        <img
            className='desktop-img-6 '
            alt=''
            src={Image6}
        />
        <img
            className='desktop-img-7 width-height-auto z-2'
            alt=''
            src={Image7}
        />
        <img
            className='desktop-img-8 '
            alt=''
            src={Image8}
        />
        <img
            className='desktop-img-9 '
            alt=''
            src={Image9}
        />
        <img
            className='desktop-img-10 width-height-auto z-2'
            alt=''
            src={Image10}
        />
        <img
            className='desktop-img-11'
            alt=''
            src={Image11}
        />
        <img
            className='desktop-img-12'
            alt=''
            src={Image12}
        />
        <WeMakeItEasy
            style={{ fontSize: '80px' }}
            desktop={true}
        />
        <div className='desktop-square-orange' />
        <div className='circle desktop-circle-blue' />
        <div className='desktop-triangle-orange' />
    </div>
);
