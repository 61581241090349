
import AboutUs from "../../Components/Landing/AboutUs";
import ContactUs from "../../Components/Landing/ContactUs";
import KeyPeople from "../../Components/Landing/KeyPeople";
import Products from "../../Components/Landing/Product";
import WhyUs from "../../Components/Landing/WhyUs";

const LandingPage = () => {
  return (
    <div className="bg-[#161F40] pt-20 ">
      <AboutUs />
      <Products />
      <WhyUs/>
      <KeyPeople />
      <ContactUs />
    </div>
  );
};

export default LandingPage;
