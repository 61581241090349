import React from "react";
const BoxLayout = ({ img, heading, para, extraClasses }) => {
  return (
    <div
      className={"" + extraClasses}
    >
      <div className="flex items-center">
        <img src={img} alt="brief-case" className="mx-auto" />
      </div>
      {heading && (
        <h5 className="text-white mont700 my-5">{heading}</h5>
      )}
      {para && <p className="mont400  text-white m-10">{para}</p>}
    </div>
  );
};
export default BoxLayout;
