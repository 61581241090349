import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import RestaurantsIcon from "./Assets/Image/AigotLogo.png";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
function NavMobile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  return (
    <div className="flex flex-col lg:hidden xmd:hidden xl:hidden">
      <div className="flex flex-row duration-300 transition-all z-10 w-full items-center px-8 justify-evenly  py-2 ">
        <div className=" w-1/3 xsm:w-2/3 sm:w-2/3 mx-auto ">
          <img
            src={RestaurantsIcon}
            alt="logo"
            className="w-[50%] h-[50%]"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        <button onClick={() => setIsNavOpen((prev) => !prev)}>
          <GiHamburgerMenu size={30} color="#FF7A00" />
        </button>

        <nav>
          <section className="MOBILE-MENU flex lg:hidden xl:hidden">
            <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
              <div className="w-full flex flex-row items-center justify-evenly absolute top-0 mt-8 ">
                <div className=" w-2/3">
                  <img
                    src={RestaurantsIcon}
                    alt="logo"
                    className="w-[50%] h-[50%] "
                  />
                </div>
                <div
                  onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
                >
                  <svg
                    className="h-8 w-8 text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#FF7A00"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
              </div>
              <ul className="w-full md:w-[80%] mx-auto h-full items-center flex flex-col ">
                <List
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  <Divider
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderWidth: 1,
                    }}
                  />

                  <a
                    href="#why-us"
                    onClick={() => {
                      setIsNavOpen((prev) => !prev);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          type="body2"
                          style={{ fontWeight: "800", fontSize: 18 }}
                        >
                          {t("nav.whyUs")}
                        </Typography>
                      }
                    />
                  </a>

                  <Divider
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderWidth: 1,
                    }}
                  />

                  <a
                    href="#development-of-saas"
                    onClick={() => {
                      setIsNavOpen((prev) => !prev);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          type="body2"
                          style={{ fontWeight: "800", fontSize: 18 }}
                        >
                          {t("nav.Solutions")}
                        </Typography>
                      }
                    />
                  </a>

                  <Divider
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderWidth: 1,
                    }}
                  />

                  <a
                    href="#products"
                    onClick={() => {
                      setIsNavOpen((prev) => !prev);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          type="body2"
                          style={{ fontWeight: "800", fontSize: 18 }}
                        >
                          {t("nav.OurProducts")}
                        </Typography>
                      }
                    />
                  </a>

                  <Divider
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderWidth: 1,
                    }}
                  />
                  <a
                    href="#contact-us"
                    onClick={() => {
                      setIsNavOpen((prev) => !prev);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          type="body2"
                          style={{ fontWeight: "800", fontSize: 18 }}
                        >
                          {" "}
                          {t("nav.contactUs")}
                        </Typography>
                      }
                    />
                  </a>

                  <Divider
                    style={{
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      borderWidth: 1,
                    }}
                  />
                </List>
              </ul>
            </div>
          </section>
        </nav>
        <style>{`
.hideMenuNav {
display: none;
}
.showMenuNav {
display: block;
position: absolute;
width: 100%;
height: 100vh;
top: 0;
left: 0;
background: white;
z-index: 10;
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: center;
}
`}</style>
      </div>
    </div>
  );
}

export default NavMobile;
