import { useContext } from 'react';
import aboutUs from '../../Assets/Image/AboutUs.png';
import ArtificialIntelligence from '../../Assets/Image/ArtificialIntelligence.png';
import DevelopmentOfSaaS from '../../Assets/Image/DevelopmentOfSaas.png';
import SoftwareAutomation from '../../Assets/Image/SoftwareAutomation.png';
import BoxLayout from './BoxLayout';
import { useTranslation } from 'react-i18next';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import Typewriter from 'typewriter-effect';
import { SectionRefsContext } from '../../Context/SectionRefsContext';

const AboutUs = () => {
    const { t } = useTranslation();
    const [solutionsRef] = useContext(SectionRefsContext);

    return (
        <div>
            <section
                id='about-us'
                className=' gap-10 bg-gradient-to-bl from-[#161F4033] via-[#161F4033] to-[#C3C0E133]'
            >
                <div className='sm:container md:container xmd:container lg:container xl:container mx-auto xl:px-20 px-4 py-8 md:py-16'>
                    <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center mb-8 md:mb-12'>
                        <div className='w-full md:order-1 mb-4 md:mb-0'>
                            <div className='text-[#FFFFFF] font-spline-sans-mono text-5xl font-bold leading-71 text-left'>
                                <p>{'/*'}{t('aboutUs.text1')}</p>
                                <div className='flex items-center'>
                                    <p className='text-[#FF7A00]'>
                                        <Typewriter
                                            options={{
                                                strings: t(
                                                    'aboutUs.typewriter',
                                                    { returnObjects: true }
                                                ),
                                                autoStart: true,
                                                loop: true,
                                                pauseFor: 2000,
                                            }}
                                        />
                                    </p>
                                    <p className='inline'>{' */'}</p>
                                </div>
                            </div>
                            <p className='text-[#FFFFFF] font-roboto text-base font-medium leading-5 text-left mt-5'>
                                {t('aboutUs.text2')}
                            </p>
                        </div>
                        <div className='w-full md:text-right md:order-2'>
                            <BoxLayout
                                img={aboutUs}
                                extraClasses='pl-32 md:pl-0 sm:pl-0 xsm:pl-0'
                            />
                        </div>
                    </div>
                    <hr className='bg-white mb-8 md:mb-12 h-1' />
                    <div className='flex flex-col  justify-between items-center'>
                        <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center'>
                            <div className='border-2 border-slate-500 w-full h-72 bg-gradient-to-tl from-[#161F4033] via-[#253056] to-[#C3C0E133] shadow-md mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 '>
                                <div className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-left text-[#C3C0E1] w-32'>
                                    {t('aboutUs.text4')}
                                </div>
                                <p className='font-roboto h-40 text-white text-base font-normal leading-19 tracking-normal text-left py-7'>
                                    {t('aboutUs.text5')}
                                </p>
                                <a
                                    className='flex items-center font-roboto text-white text-base font-normal leading-19 tracking-normal underline'
                                    href='#development-of-saas'
                                >
                                    {t('aboutUs.text6')}
                                    <MdOutlineDoubleArrow className='ml-2' />
                                </a>
                            </div>
                            <div className='border-2 border-slate-500 w-full h-72 bg-gradient-to-tl from-[#161F4033] via-[#253056] to-[#C3C0E133] shadow-md mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10'>
                                <div className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-left text-[#C3C0E1] w-40'>
                                    {t('aboutUs.text7')}
                                </div>
                                <p className='font-roboto h-40 text-white text-base font-normal leading-19 tracking-normal text-left py-7'>
                                    {t('aboutUs.text8')}
                                </p>
                                <a
                                    className='flex items-center font-roboto text-white text-base font-normal leading-19 tracking-normal underline'
                                    href='#qoftware-automation'
                                >
                                    {t('aboutUs.text9')}
                                    <MdOutlineDoubleArrow className='ml-2' />
                                </a>
                            </div>
                            <div className='border-2 border-slate-500 w-full h-72 bg-gradient-to-tl from-[#161F4033] via-[#253056] to-[#C3C0E133] shadow-md mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10'>
                                <div className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-left text-[#C3C0E1] w-32'>
                                    {t('aboutUs.text10')}
                                </div>
                                <p className='font-roboto h-40 text-white text-base font-normal leading-19 tracking-normal text-left py-7'>
                                    {t('aboutUs.text11')}
                                </p>
                                <a
                                    className='flex items-center font-roboto text-white text-base font-normal leading-19 tracking-normal underline'
                                    href='#artificial-intelligence'
                                >
                                    {t('aboutUs.text12')}
                                    <MdOutlineDoubleArrow className='ml-2' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id='solutions'
                ref={solutionsRef}
            >
                <section id='development-of-saas'>
                    <div className='px-10 py-20 md:py-16 bg-[#FFCB80] w-full'>
                        <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center mb-8 md:mb-12'>
                            <div className='w-full md:text-right md:order-2'>
                                <BoxLayout img={DevelopmentOfSaaS} />
                            </div>
                            <div className='w-full flex flex-col'>
                                <div className='w-1/2 mx-auto '>
                                    <div className='text-black font-spline-sans-mono text-3xl font-bold leading-71 text-left w-32'>
                                        {t('aboutUs.text4')}
                                    </div>
                                    <p className='text-[#475467] font-roboto text-sm font-medium leading-5 text-left'>
                                        {t('aboutUs.text13')}
                                    </p>
                                    <p className='text-[#475467] font-roboto text-sm font-medium leading-5 text-left'>
                                        {t('aboutUs.text14')}
                                    </p>
                                    <br />
                                    <div className='text-left'>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text15')}
                                        </p>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text16')}
                                        </p>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text17')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='qoftware-automation'>
                    <div className='px-10 py-20 md:py-16 bg-[#ffffff] w-full'>
                        <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center mb-8 md:mb-12'>
                            <div className='w-full md:text-right md:order-2'>
                                <BoxLayout img={SoftwareAutomation} />
                            </div>
                            <div className='w-full flex flex-col'>
                                <div className='w-1/2 mx-auto '>
                                    <div className='text-black font-spline-sans-mono text-3xl font-bold leading-71 text-left w-32'>
                                        {t('aboutUs.text7')}
                                    </div>
                                    <p className='text-[#475467] font-roboto text-sm font-medium leading-5 text-left'>
                                        {t('aboutUs.text18')}
                                    </p>
                                    <br />
                                    <div className='text-left'>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text19')}
                                        </p>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text20')}
                                        </p>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text21')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='artificial-intelligence'>
                    <div className='px-10 py-20 md:py-16 bg-[#C3C0E1] w-full'>
                        <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center mb-8 md:mb-12'>
                            <div className='w-full md:text-right md:order-2'>
                                <BoxLayout img={ArtificialIntelligence} />
                            </div>
                            <div className='w-full flex flex-col'>
                                <div className='w-1/2 mx-auto '>
                                    <div className='text-black font-spline-sans-mono text-3xl font-bold leading-71 text-left w-32'>
                                        {t('aboutUs.text10')}
                                    </div>
                                    <p className='text-[#475467] font-roboto text-sm font-medium leading-5 text-left'>
                                        {t('aboutUs.text22')}
                                    </p>
                                    <br />
                                    <div className='text-left'>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text23')}
                                        </p>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text24')}
                                        </p>
                                        <p className='text-[#FFFFFF] bg-[#161F40] font-roboto text-sm font-medium leading-5 text-left px-6 py-1 my-1 mx-1 rounded-[30px] inline-block min-w-min'>
                                            {t('aboutUs.text25')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
};

export default AboutUs;
