import { useTranslation } from 'react-i18next';
import Maindcare from '../../Assets/Image/Maindcare.png';
import NlpSearchEngine from '../../Assets/Image/NlpSearchEngine.png';
import RestaurantsClub from '../../Assets/Image/RestaurantsClub.png';
import BoxLayout from './BoxLayout';
import { useContext } from 'react';
import { SectionRefsContext } from '../../Context/SectionRefsContext';

const Products = () => {
    const { t } = useTranslation();
    const [, productsRef] = useContext(SectionRefsContext);

    return (
        <div ref={productsRef}>
            <section
                id='products'
                className=' gap-10 bg-white'
            >
                <div className='sm:container md:container xmd:container lg:container xl:container mx-auto xl:px-20 px-4 py-8 md:py-16'>
                    <div className='flex flex-col  justify-between items-center'>
                        <div className='text-[#1E1C0F] font-spline-sans-mono text-6xl font-bold leading-71 text-center'>
                            <p className='inline'>{'/*'}</p>
                            <p className='inline'>{t('products.text1')} </p>
                            <p className='inline text-[#FF7A00]'>
                                {' '}
                                {t('products.text2')}
                            </p>
                            <p className='inline'>*/</p>
                        </div>
                        <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center'>
                            <div className=' w-full h-100  mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 flex flex-col justify-center items-center'>
                                <BoxLayout img={RestaurantsClub} />
                                <h3 className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-center text-[#1E1C0F] w-32'>
                                    {t('products.text3')}
                                </h3>
                                <p className='font-roboto h-40 text-[#475467] text-base font-normal leading-19 tracking-normal text-center py-7'>
                                    {t('products.text4')}
                                </p>
                            </div>
                            <div className=' w-full h-100  mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 flex flex-col justify-center items-center'>
                                <BoxLayout img={Maindcare} />
                                <h3 className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-center text-[#1E1C0F] w-32'>
                                    {t('products.text5')}
                                </h3>
                                <p className='font-roboto h-40 text-[#475467] text-base font-normal leading-19 tracking-normal text-center py-7'>
                                    {t('products.text6')}
                                </p>
                            </div>
                            <div className=' w-full h-100  mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 flex flex-col justify-center items-center'>
                                <BoxLayout img={NlpSearchEngine} />
                                <h3 className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-center text-[#1E1C0F] w-32'>
                                    {t('products.text7')}
                                </h3>
                                <p className='font-roboto h-40 text-[#475467] text-base font-normal leading-19 tracking-normal text-center py-7'>
                                    {t('products.text8')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Products;
