import React from 'react';
import { useTranslation } from 'react-i18next';

export const WeMakeItEasy = ({ style, desktop }) => {
    const { t } = useTranslation();
    return (
        <div
            className={`we-make-it-easy  font-bold text-[#161F40]   ${
                desktop ? 'desktop' : 'mobile'
            } `}
        >
            <div>
                <span className='inline'>{'/*'}</span>
                <span className='inline'>{t('keyPeople.text11')} </span>
                <p />
                <span className='inline'>{t('keyPeople.text12')} </span>
                <span className='inline text-[#FF7A00]'>
                    {t('keyPeople.text13')}
                </span>
                <span className='inline'>*/</span>
            </div>
        </div>
    );
};
