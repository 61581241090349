import React from 'react';
import '../../Policies.css';

export const TermsAndConditions = () => {
    return (
        <div
            style={{ marginTop: '70px' }}
            className='sm:container md:container xmd:container lg:container xl:container mx-auto xl:px-20 px-4 py-8 md:py-16'
        >
            <p>
                <strong>COOKIE POLICY </strong>
            </p>
            <p>
                Tramite questa pagina AiGot. S.r.l. fornisce all&rsquo;Utente
                informazioni su tipologie, finalit&agrave; e&nbsp;
                modalit&agrave; di utilizzo e gestione dei cookie presenti sul
                sito internet{' '}
                <strong>
                    <em>https://www.restaurants.club </em>
                </strong>
                e&nbsp; sul sito internet{' '}
                <strong>
                    <em>htttps://www.aigot.com </em>
                </strong>
                (d&rsquo;ora innnanzi &ldquo;<strong>Siti</strong>&rdquo;) nel
                rispetto della normativa in&nbsp; vigore (D.Lgs. 196/2003 e Reg.
                UE 679/2016 &ndash; GDPR).
            </p>
            <p>
                <strong>COSA SONO I COOKIE? </strong>
            </p>
            <p>
                I cookie sono porzioni di informazioni che i Siti inseriscono
                nel dispositivo di navigazione&nbsp; dell&rsquo;Utente
                (computer, tablet, smartphone) quando visita una pagina. Possono
                comportare la&nbsp; trasmissione di informazioni tra i Siti ed
                il dispositivo, e tra quest&rsquo;ultimo e altri siti che
                operano&nbsp; per conto di AiGot S.r.l. o in privato.&nbsp;
            </p>
            <p>
                Sono utilizzati, ad esempio, per consentire o migliorare la
                navigazione tra le pagine dei Siti,&nbsp; registrando e
                ricordando le preferenze dell&rsquo;Utente. Inoltre, solo previo
                consenso dell&rsquo;Utente, i Siti&nbsp; potrebbero utilizzare i
                cookie per mostrare, sia dai Siti stessi o da siti web di terzi,
                annunci&nbsp; pubblicitari basati sull'analisi delle abitudini
                di navigazione dell'Utente.
            </p>
            <p>
                <strong>&Egrave; POSSIBILE BLOCCARE I COOKIE?</strong>
            </p>
            <p>
                S&igrave;, &egrave; possibile bloccare o consentire i cookie in
                tutti i siti web oppure scegliere i siti per i quali sono
                consentiti.
            </p>
            <p>
                <strong>TIPOLOGIE DI COOKIE UTILIZZATI </strong>
            </p>
            <p>
                I Siti potrebbero utilizzare differenti tipologie di cookie,
                anche in dipendenza delle preferenze e&nbsp; del consenso
                fornito dall&rsquo;Utente:
            </p>
            <p>
                <strong>Cookie Tecnici </strong>
            </p>
            <p>
                I cookie tecnici possono essere suddivisi in cookie di
                navigazione o di sessione, permanenti e analitici.
            </p>

            <p>
                <strong>1) Di navigazione </strong>
                <strong>o di sessione</strong>
            </p>
            <p>
                I cookie di navigazione o di sessione sono essenziali per
                sfruttare appieno le caratteristiche&nbsp; dei Siti ad avere
                accesso ai servizi (ad esempio autenticare l&rsquo;Utente al
                momento&nbsp; dell&rsquo;accesso, se si tratta di un Utente
                precedentemente registrato).
            </p>
            <p>
                Tali cookie sono attivi soltanto per la durata di una sessione
                di navigazione, mentre i cookie <strong>permanenti</strong>{' '}
                rimangono attivi per pi&ugrave; visite e vengono utilizzati
                per&nbsp; ricordare le preferenze dell&rsquo;Utente e
                memorizzare le azioni compiute durante le visite sui&nbsp; Siti.
            </p>
            <p>
                Questi cookie consentono ai Siti di memorizzare le scelte fatte
                dall&rsquo;Utente (nome, lingua o&nbsp; luogo di provenienza) e
                forniscono funzionalit&agrave; avanzate personalizzate. Questi
                cookie&nbsp; possono essere utilizzati anche per ricordare le
                modifiche apportate alla dimensione del&nbsp; testo, font ed
                altre parti di pagine web che &egrave; possibile personalizzare.
            </p>
            <p>
                <strong>2) Di performance o analitici</strong>
            </p>
            <p>
                I cookie di questo tipo vengono utilizzati &nbsp;per raccogliere
                informazioni riguardo alle modalit&agrave; di utilizzo dei Siti
                da parte&nbsp; degli utenti al fine di migliorare la
                qualit&agrave; degli stessi e dei servizi offerti, tenendo
                traccia&nbsp; delle pagine visitate con maggiore frequenza e
                delle attivit&agrave; sui Siti. I cookie di&nbsp; performance
                consentono a AiGot S.r.l. di applicare le scelte effettuate
                dall&rsquo;Utente e di&nbsp; ottimizzare il funzionamento dei
                Siti, permettendo anche l&rsquo;immediata identificazione
                e&nbsp; risoluzione di tutte le problematiche tecniche che si
                potrebbero incontrare durante la&nbsp; navigazione
                all&rsquo;interno del sito. Tutte le informazioni che questi
                cookie conservano sono aggregate e anonime e non vengono
                utilizzate per raccogliere dati personali a scopi&nbsp;
                pubblicitari.
            </p>
            <p>
                <strong>Cookie di profilazione&nbsp; </strong>
            </p>
            <p>
                Oltre ai cookie tecnici, i siti potrebbero utilizzare anche i
                cookie di profilazione. Mediante tali cookie, i Siti possono
                creare un profilo di ciascun Utente, basato sulle preferenze ed
                abitudini manifestati durante la navigazione nel web, anche al
                fine di inviare messaggi coerenti con il&nbsp; profilo
                dell&rsquo;Utente.
            </p>
            <p>
                Come previsto dalla normativa vigente, i Siti utilizzano i
                cookie di profilazione solo previo&nbsp; consenso
                dell&rsquo;Utente, espresso mediante il banner presente al primo
                accesso; inoltre, ciascun&nbsp; Utente &egrave; libero di
                bloccare l&rsquo;installazione dei cookie di profilazione in
                qualsiasi momento, senza&nbsp; che la possibilit&agrave; di
                visitare i Siti e fruire dei suoi contenuti sia in qualsiasi
                modo compromessa.
            </p>
            <p>
                Le informazioni raccolte sono a beneficio esclusivo di AiGot
                S.r.l. e verranno condivise con&nbsp; soggetti terzi solo per le
                finalit&agrave; di elaborazione di tali informazioni per conto
                della Societ&agrave; stessa.&nbsp; In determinate circostanze,
                AiGot S.r.l. potrebbe incrociare i dati raccolti tramite cookie
                con i dati&nbsp; personali dell&rsquo;Utente raccolti per le
                finalit&agrave; indicate nella Privacy Policy pubblicata sui
                medesimi&nbsp; Siti.
            </p>
            <p>
                <strong>Cookie propri o di terze parti </strong>
            </p>
            <p>
                I cookie possono essere suddivisi in cookie propri o cookie di
                terze parti:
            </p>
            <p>
                1) I <strong>cookie propri </strong>appartengono al titolare
                dell&rsquo;applicazione e raccolgono dati dell&rsquo;utente
                accessibili solo al titolare. Si tratta, ad esempio, dei cookie
                che ricordano quali articoli sono stati inseriti nel carrello di
                un Utente mentre l'Utente prosegue nella navigazione sui
                Siti.&nbsp;
            </p>
            <p>
                2) I <strong>cookie di terze parti </strong>sono cookie
                predisposti e gestiti da siti diversi da quelli della&nbsp;
                Societ&agrave; ed installati per il tramite dei Siti.
            </p>
            <p>
                Alcuni hanno lo scopo di ottenere i dati in modo anonimo, ad
                esempio le statistiche di&nbsp; navigazione.&nbsp;
            </p>
            <p>
                Altri cookie di terze parti, invece, potrebbero tenere traccia
                della risposta fornita dai&nbsp; visitatori alle attivit&agrave;
                dei siti di AiGot S.r.l., eventualmente valutando le visite da
                parte&nbsp; dell&rsquo;Utente a tali Siti o ai siti di terze
                parti su cui si trovano annunci pubblicitari, per&nbsp;
                visualizzare annunci mirati o individuare quali banner
                pubblicitari sono in grado di attirare&nbsp; utenti.
            </p>
            <p>
                <strong>GESTIONE DELLE PREFERENZE RIGUARDO AI COOKIE </strong>
            </p>
            <p>
                Ciascun Utente ha la possibilit&agrave; di scegliere in
                qualsiasi momento di limitare, eliminare, bloccare o&nbsp;
                limitare parzialmente l&rsquo;uso dei cookie modificando le
                impostazioni del proprio browser di&nbsp; navigazione.&nbsp;
            </p>
            <p>
                La configurazione dei cookie si trova normalmente nel menu
                "Preferenze" o "Strumenti".&nbsp; Tramite i seguenti
                collegamenti &egrave; possibile personalizzare la gestione dei
                cookie dei browser pi&ugrave;&nbsp; diffusi:
            </p>
            <p>
                <u>Chrome</u>, <u>Firefox </u>, &nbsp;
                <u>Internet Explorer 7 en 8</u> ,{' '}
                <u>Internet Explorer 9, Safari</u>, <u>Opera </u>,{' '}
                <u>Facebook</u>, <u>Instagram</u>, <u>Whatsapp</u>,{' '}
                <u>Linkedin</u>.
            </p>
            <p>
                Si ricorda che la completa disabilitazione dei cookie potrebbe
                compromettere la corretta&nbsp; funzionalit&agrave; di alcune
                parti dei Siti (navigazione tra le pagine del sito, la
                possibilit&agrave; di fare&nbsp; acquisti, perdita delle
                funzioni di personalizzazione).&nbsp;
            </p>
            <p>
                Per ulteriori informazioni e per disattivare i cookie di terze
                parti:{' '}
                <a href='https://www.youronlinechoices.com/'>
                    https://www.youronlinechoices.com/
                </a>
            </p>

            <p>
                <strong>TITOLARE DEL TRATTAMENTO DEI DATI </strong>
            </p>
            <p>
                Il titolare del trattamento: AiGot S.r.l., con sede legale in
                Milano (MI) via Visconti di Modrone 38, cap 20122, indirizzo
                e-mail privacy@aigot.com, indirizzo PEC aigot@pec.com, telefono
                0500985159.
            </p>

            <p>
                <strong>RESPONSABILE DELLA PROTEZIONE DEI DATI </strong>
            </p>

            <p>
                Il responsabile della protezione dei dati (DPO) di AiGot
                pu&ograve; essere contatto al seguente indirizzo email:
                privacy@aigot.com
            </p>
            <p>
                <strong>PRIVACY POLICY&nbsp; </strong>
            </p>
            <p>
                Per maggiori informazioni in merito al trattamento dei dati
                personali svolto dai siti internet della&nbsp; Societ&agrave;,
                si rimanda alla Privacy Policy.
            </p>
        </div>
    );
};
