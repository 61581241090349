import { BsLinkedin } from 'react-icons/bs';
import AigotLogo from './Assets/Image/AigotLogo.png';
import { useTranslation } from 'react-i18next';
import EthicCode from './Assets/Docs/Codice-etico-AIGOT.pdf';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className='bg-[#161F40] xl:px-10 sm:px-10 xsm:px-10 text-left p-10'>
                <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col'>
                    <div className='w-full px-10 xl:w-1/4 lg:w1/4 md:w-full sm:w-full xsm:w-full text-left pb-5'>
                        <img
                            src={AigotLogo}
                            alt='Brand Logo'
                            className='flex mr-auto md:mx-auto sm:mx-auto xsm:mx-auto w-20 h-5 '
                        />
                        <div className='w-full mt-5 text-left md:text-center sm:text-center xsm:text-center'>
                            <p className='mont600 text-white'>
                                {t('footer.text1')}
                            </p>
                            <p className='mont400 text-white'>
                                {t('footer.text2')} <br /> 20122
                            </p>
                        </div>
                    </div>
                    <div className='w-full px-10 xl:w-1/4 lg:w1/4 md:w-full sm:w-full xsm:w-full text-left pb-5'>
                        <div className='w-full mt-10 text-left md:text-center sm:text-center xsm:text-center md:mt-0 sm:mt-0 xsm:mt-0'>
                            <p className='mont600 text-white'>
                                {t('footer.text3')}
                            </p>
                            <p className='mont400 text-white'>
                                {t('footer.text4')}
                            </p>
                        </div>
                    </div>
                    <div className='w-full px-10 xl:w-1/4 lg:w1/4 md:w-full sm:w-full xsm:w-full text-left pb-5'>
                        <div className='w-full mt-10 text-left md:text-center sm:text-center xsm:text-center md:mt-0 sm:mt-0 xsm:mt-0'>
                            <p className='mont400 text-white'>
                                {t('footer.text5')}
                                <br />
                                {t('footer.text6')}
                            </p>
                        </div>
                    </div>
                    <div className='w-full px-10 xl:w-1/4 lg:w1/4 md:w-full sm:w-full xsm:w-full text-left pb-5 md:text-center sm:text-center xsm:text-center'>
                        <p className='mont600 text-white h-5'>
                            {t('footer.text7')}
                        </p>
                        <div className='w-full mt-5 text-left md:justify-center sm:justify-center xsm:justify-center md:flex sm:flex xsm:flex '>
                            <a
                                target='_blank'
                                rel="noreferrer"
                                href='https://www.linkedin.com/company/aigot-srl/'
                                className='mr-6 text-white flex items-center'
                            >
                                <BsLinkedin
                                    className='text-teal-50 hover:text-blue-500 rounded-full'
                                    size={30}
                                />
                                <span className='pl-2'>Linkedin</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap xsm:flex-col sm:flex-col xsm:flex-col mt-10 justify-center'>
                    <p className='mont400 text-white text-center max-w-3xl'>
                        {t('footer.text8')}
                    </p>
                </div>
            </section>
            <div className='bg-[#253056] py-4 flex justify-center items-center text-white gap-2 [&>a:hover]:underline '>
                <NavLink
                    to={'/terms-and-conditions'}
                    className='text-center mont400  text-xl'
                >
                    {t('footer.text9')}
                </NavLink>
                <span>{'-'}</span>
                <NavLink
                    to={'/privacy-policy'}
                    className='text-center  mont400  text-xl'
                >
                    {t('footer.text10')}
                </NavLink>
                <span>{'-'}</span>
                <a
                    href={EthicCode}
                    className='text-center mont400  text-xl'
                >
                    {t('footer.text11')}
                </a>
            </div>
        </>
    );
};

export default Footer;
