import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import BrandLogo from './Assets/Image/AigotLogo.png';
import './Nav.css';
import NavMobile from './NavMobile';
import { useTranslation } from 'react-i18next';
import { SectionRefsContext } from './Context/SectionRefsContext';

let clickActionTriggered = false;
const Nav = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(null);
  const dropdownRef = useRef(null);
  const sections = useContext(SectionRefsContext);
  const navbarRef = useRef();

  const handleLinkClick = (index) => {
    clickActionTriggered = true;
    setActiveSection(index);
    scrollToSection(sections[index]);
  };

  const handleScroll = useCallback(() => {
    if (clickActionTriggered) {
      return;
    }
    const firstSectionOffset = sections[0].current?.offsetTop;
    const lastSection = sections[sections.length - 1];
    const lastSectionOffsetBottom =
      lastSection.current?.offsetTop + lastSection.current?.offsetHeight;

    sections.forEach((ref) => {
      const sectionTop = ref.current?.offsetTop;
      if (
        (firstSectionOffset &&
          window.scrollY + navbarRef.current.clientHeight <
          firstSectionOffset) ||
        window.scrollY > lastSectionOffsetBottom
      ) {
        setActiveSection(null);
      } else if (
        window.scrollY + navbarRef.current.clientHeight >=
        sectionTop
      ) {
        setActiveSection(sections.indexOf(ref));
      }
    });
  }, [sections]);

  const handleScrollEnd = () => {
    if (clickActionTriggered === true) {
      clickActionTriggered = false;
    }
  };

  const scrollToSection = (ref) => {
    const top =
      ref?.current?.offsetTop - navbarRef?.current?.clientHeight + 1;
    window?.scrollTo({
      behavior: 'smooth',
      top,
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    document.addEventListener('scrollend', handleScrollEnd);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('scrollend', handleScrollEnd);
    };
  }, [handleScroll]);

  const buttonConfigs = [
    { id: 0, key: 'nav.Solutions' },
    { id: 1, key: 'nav.OurProducts' },
    { id: 2, key: 'nav.whyUs' }
  ];

  return (
    <div
      ref={navbarRef}
      className='pb-5 flex items-center flex-col bg-[#293152] fixed top-0 left-0 w-full z-10'
    >
      <NavMobile />
      <div className='sm:hidden xsm:hidden md:hidden w-full '>
        <div className='mt-5 flex justify-between items-center'>
          <div className='mx-10'>
            <img
              src={BrandLogo}
              alt='Brand Logo'
              className='h-10'
            />
          </div>
          <div className='flex-grow flex justify-center'>
            {buttonConfigs.map(({ id, key }) => (
              <button
                key={id}
                className={`mx-10 text-white text-16 ${activeSection === id ? 'font-bold underline' : ''}`}
                href='/'
                onClick={() => handleLinkClick(id)}
              >
                {activeSection === id ? '< ' : ''}{t(key)}{activeSection === id ? ' />' : ''}
              </button>
            ))}
          </div>

          <div className='mr-10'>
            <a
              className='bg-[#FF7A00] text-white text-16 font-bold text-center py-[8px] px-[30px]'
              href='#contact-us'
            >
              {t('nav.contactUs')}
            </a>
            <div
              className='relative inline-block text-left mx-20'
              ref={dropdownRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
