import React from 'react';
import Image2 from '../../../Assets/Image/mobile_people_section_2.png';
import Image3 from '../../../Assets/Image/mobile_people_section_3.png';
import './KeyPeople.css';

import { WeMakeItEasy } from '../WeMakeItEasy';

export const MobilePeopleGrid = ({
    Image1,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
}) => (
    <div className='mobile-grid '>
        <img
            className='mobile-img-1 width-height-auto z-2'
            alt=''
            src={Image1}
        />
        <img
            className='mobile-img-2  aspect'
            alt=''
            src={Image2}
        />
        <img
            className='mobile-img-3  aspect'
            alt=''
            src={Image3}
        />
        <img
            className='mobile-img-4 width-height-auto z-2'
            alt=''
            src={Image4}
        />
        <img
            className='mobile-img-5 '
            alt=''
            src={Image5}
        />
        <img
            className='mobile-img-6 '
            alt=''
            src={Image6}
        />
        <img
            className='mobile-img-7 width-height-auto z-2'
            alt=''
            src={Image7}
        />
        <img
            className='mobile-img-8 '
            alt=''
            src={Image8}
        />
        <img
            className='mobile-img-9 aspect-half'
            alt=''
            src={Image9}
        />
        <img
            className='mobile-img-10 width-height-auto z-2'
            alt=''
            src={Image10}
        />
        <img
            className='mobile-img-11'
            alt=''
            src={Image11}
        />
        <img
            className='mobile-img-12'
            alt=''
            src={Image12}
        />
        <WeMakeItEasy
            desktop={false}
            style={{ fontSize: '30px' }}
        />
        <div className='circle mobile-circle-orange' />
        <div className='circle mobile-circle-blue' />
        <div className='mobile-triangle-orange' />
    </div>
);
