import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ContactUs = () => {
    const { t } = useTranslation();
    useEffect(() => {
        const script = document.createElement('script');
        script.src = process.env.REACT_APP_HUBSPOT_SCRIPT_URL; // Use the environment variable here
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: process.env.REACT_APP_HUBSPOT_REGION,
                    portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
                    formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
                    target: process.env.REACT_APP_HUBSPOT_FORM_TARGET,
                });
            }
        });
    }, []);

    return (
        <section
            id='contact-us'
            className='gap-10 p-10 bg-white'
        >
            <div className='bg-[#253056]'>
                <div className='flex flex-row md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse justify-between mb-8 md:mb-12'>
                    <div className='w-full md:order-1 py-20 text-[#FFFFFF] font-spline-sans-mono text-5xl font-bold leading-71'>
                        {t('contactUs.GetInTouch')}
                    </div>
                    <div className='w-full flex justify-center items-center'>
                        <div className='w-sm md:order-2 bg-[#D9D9D9] my-10 p-10'>
                            <div id='hubspotForm'></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
