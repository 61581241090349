import Staff from '../../Assets/Image/Staff.png';
import { useTranslation } from 'react-i18next';
import BoxLayout from './BoxLayout';
// import '../../KeyPeople.css';
import { MobilePeopleGrid } from '../KeyPeopleGrid/Mobile';
import Image2 from '../../Assets/Image/mobile_people_section_2.png';
import Image1 from '../../Assets/Image/people_section_1.png';
import Image3 from '../../Assets/Image/mobile_people_section_3.png';
import Image4 from '../../Assets/Image/people_section_4.png';
import Image5 from '../../Assets/Image/people_section_5.png';
import Image6 from '../../Assets/Image/people_section_6.png';
import Image7 from '../../Assets/Image/people_section_7.png';
import Image8 from '../../Assets/Image/people_section_8.png';
import Image9 from '../../Assets/Image/people_section_9.png';
import Image10 from '../../Assets/Image/people_section_10.png';
import Image11 from '../../Assets/Image/people_section_11.png';
import Image12 from '../../Assets/Image/people_section_12.png';
import { DesktopPeopleGrid } from '../KeyPeopleGrid/Desktop';

const KeyPeople = () => {
    const { t } = useTranslation();

    return (
        <section
            id='key-people'
            className=' gap-10 bg-white'
        >
            <div className='sm:container md:container xmd:container lg:container xl:container mx-auto xl:px-20 px-4 py-8 md:py-16'>
                <div className='flex flex-col  justify-between items-center'>
                    <div className='text-[#161F40] font-spline-sans-mono text-5xl font-bold leading-71 text-center'>
                        <p className='inline'>{'/*'}</p>
                        <p className='inline'>{t('keyPeople.text1')} </p>
                        <p className='inline text-[#FF7A00]'>
                            {' '}
                            {t('keyPeople.text2')}
                        </p>
                        <p></p>
                        <p className='inline'>{t('keyPeople.text3')}</p>
                        <p className='inline text-[#FF7A00]'>
                            {' '}
                            {t('keyPeople.text4')}
                        </p>
                        <p className='inline'>.*/</p>
                    </div>
                </div>
                <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center mb-8 md:mb-12 py-10'>
                    <div className='w-full md:order-1 mb-4 md:mb-0'>
                        <h2 className='text-[#475467] font-spline-sans-mono text-3xl font-semibold leading-71 text-left'>
                            <p>{t('keyPeople.text5')}</p>
                            <p>{t('keyPeople.text6')}</p>
                            <p>{t('keyPeople.text7')}</p>
                            <p>{t('keyPeople.text8')}</p>
                        </h2>
                        <p className='text-[#475467] font-roboto text-base font-medium leading-5 text-left py-5'>
                            <p>{t('keyPeople.text9')}</p>
                            <p>{t('keyPeople.text10')}</p>
                        </p>
                    </div>
                    <div className='w-full md:text-right md:order-2'>
                        <BoxLayout
                            img={Staff}
                            extraClasses='pl-32 md:pl-0 sm:pl-0 xsm:pl-0'
                        />
                    </div>
                </div>
                <div className='xmd:hidden lg:hidden xl:hidden'>
                    <MobilePeopleGrid
                        Image2={Image2}
                        Image1={Image1}
                        Image3={Image3}
                        Image4={Image4}
                        Image5={Image5}
                        Image6={Image6}
                        Image7={Image7}
                        Image8={Image8}
                        Image9={Image9}
                        Image10={Image10}
                        Image11={Image11}
                        Image12={Image12}
                    />
                </div>
                <div className='xsm:hidden sm:hidden md:hidden'>
                    <DesktopPeopleGrid
                        Image2={Image2}
                        Image1={Image1}
                        Image3={Image3}
                        Image4={Image4}
                        Image5={Image5}
                        Image6={Image6}
                        Image7={Image7}
                        Image8={Image8}
                        Image9={Image9}
                        Image10={Image10}
                        Image11={Image11}
                        Image12={Image12}
                    />
                </div>
            </div>
            <script
                charset='utf-8'
                type='text/javascript'
                src='//js-eu1.hsforms.net/forms/embed/v2.js'
            ></script>
        </section>
    );
};

export default KeyPeople;
