import { createContext, createRef } from 'react';

const solutionsRef = createRef();
const productsRef = createRef();
const whyUsRef = createRef();

const refs = [solutionsRef, productsRef, whyUsRef];

export const SectionRefsContext = createContext(null);

export const SectionRefsContextProvider = ({ children }) => (
    <SectionRefsContext.Provider value={refs}>
        {children}
    </SectionRefsContext.Provider>
);
