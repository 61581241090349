import { useTranslation } from 'react-i18next';
import WhyUs1 from '../../Assets/Image/WhyUs1.png';
import WhyUs2 from '../../Assets/Image/WhyUs2.png';
import WhyUs3 from '../../Assets/Image/WhyUs3.png';
import BoxLayout from './BoxLayout';
import { useContext } from 'react';
import { SectionRefsContext } from '../../Context/SectionRefsContext';

const WhyUs = () => {
    const { t } = useTranslation();
    const [, , whyUsRef] = useContext(SectionRefsContext);
    return (
        <div ref={whyUsRef}>
            <section
                id='why-us'
                className=' gap-10 bg-gradient-to-bl from-[#161F4033] via-[#161F4033] to-[#C3C0E133]'
            >
                <div className='sm:container md:container xmd:container lg:container xl:container mx-auto xl:px-20 px-4 py-8 md:py-16'>
                    <div className='flex flex-col  justify-between items-center'>
                        <div className='text-white font-spline-sans-mono text-6xl font-bold leading-71 text-center pb-10'>
                            <p className='inline'>{'/*'}</p>
                            <p className='inline'>{t('whyUs.text1')} </p>
                            <p className='inline text-[#FF7A00]'>
                                {' '}
                                {t('whyUs.text2')}
                            </p>
                            <p className='inline'>?*/</p>
                        </div>
                        <div className='flex flex-row md:flex-col sm:flex-col xsm:flex-col justify-between items-center'>
                            <div className=' w-full h-100  mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 flex flex-col justify-center items-center'>
                                <BoxLayout
                                    img={WhyUs1}
                                    extraClasses={'xl:mr-auto lg:mr-auto pb-5'}
                                />
                                <h3 className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-center text-white xl:text-left lg:text-left w-full'>
                                    {t('whyUs.text3')}
                                </h3>
                                <p className='font-roboto h-40 text-white text-base font-normal leading-19 tracking-normal text-center py-7 xl:text-left lg:text-left w-full'>
                                    {t('whyUs.text4')}
                                </p>
                            </div>
                            <div className=' w-full h-100  mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 flex flex-col justify-center items-center'>
                                <BoxLayout
                                    img={WhyUs2}
                                    extraClasses={'xl:mr-auto lg:mr-auto pb-5'}
                                />
                                <h3 className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-center text-white xl:text-left lg:text-left w-full'>
                                    {t('whyUs.text5')}
                                </h3>
                                <p className='font-roboto h-40 text-white text-base font-normal leading-19 tracking-normal text-center py-7 xl:text-left lg:text-left w-full'>
                                    {t('whyUs.text6')}
                                </p>
                            </div>
                            <div className=' w-full h-100  mx-2 p-4 mb-4 md:mb-10 sm:mb-10 xsm:mb-10 flex flex-col justify-center items-center'>
                                <BoxLayout
                                    img={WhyUs3}
                                    extraClasses={'xl:mr-auto lg:mr-auto pb-5'}
                                />
                                <h3 className='font-spline-sans-mono text-xl font-bold leading-28 tracking-normal text-center text-white xl:text-left lg:text-left w-full'>
                                    {t('whyUs.text7')}
                                </h3>
                                <p className='font-roboto h-40 text-white text-base font-normal leading-19 tracking-normal text-center py-7 xl:text-left lg:text-left w-full'>
                                    {t('whyUs.text8')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WhyUs;
