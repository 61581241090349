import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import LandingPage from './Pages/LandingPage/LandingPage';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import enTranslation from './locales/en.json';
import itTranslation from './locales/it.json';
import { SectionRefsContextProvider } from './Context/SectionRefsContext';
import { TermsAndConditions } from './Pages/TermsAndConditions/TermsAndConditions';
import { PrivacyPolicy } from './Pages/PrivacyPolicy/PrivacyPolicy';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: enTranslation },
        it: { translation: itTranslation },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

function App() {
    return (
        <SectionRefsContextProvider>
            <Router>
                 <div className='App'>
                <Nav />
                <Routes>
                    <Route
                        exact
                        path='/'
                        element={<LandingPage />}
                    />
                    <Route
                        exact
                        path='/terms-and-conditions'
                        element={<TermsAndConditions />}
                    />
                    <Route
                        exact
                        path='/privacy-policy'
                        element={<PrivacyPolicy />}
                    />
                </Routes>
                <Footer />
            </div>
            </Router>
        </SectionRefsContextProvider>
    );
}

export default App;
